import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Icon, LineChartExample } from "components/Component";
import { ChatPerDayData } from "./homeData";
import { useCookies } from "react-cookie";
import { serviceGetUsersBySales } from "../../services/users";
import { getRating } from "../../services/dashboard";

const RatingSales = (props) => {
  const { selectedDepartment } = props;
  const [cookies] = useCookies(["user"]);
  const [data, setData] = useState(null);
  const [dataUser, setDataUser] = useState(null);
  const [datePeriod, setDatePeriod] = useState("DAILY");
  const [cardType, setCardType] = useState("RATING");
  const [percentage, setPercentage] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const getDataRatingAll = async (datePeriod) => {
    // const responseGetDataTotal = await getRating(cookies.SIDikey, datePeriod, cardType, cookies.departmentId, selectedUser ? selectedUser.id : cookies.id, cookies.role);
    const responseGetDataTotal = await getRating(
      cookies.SIDikey,
      datePeriod,
      cardType,
      selectedDepartment.value,
      // selectedUser ? selectedUser.id : cookies.id,
      selectedUser?.id,
      roleFunctions
    );
    setData(responseGetDataTotal.data);
    const currentTotal = Number(responseGetDataTotal?.data?.currentTotal);
    const lastTotal = Number(responseGetDataTotal?.data?.lastTotal);
    if (currentTotal !== lastTotal) {
      if (lastTotal !== 0) {
        setPercentage(Math.round(((currentTotal - lastTotal) / lastTotal) * 100));
      } else {
        setPercentage(100);
      }
    } else {
      setPercentage(0);
    }
  };

  const getDataUser = async () => { 
      const responseGetDataUser = await serviceGetUsersBySales(cookies.SIDikey, selectedDepartment.value, roleFunctions);
      setDataUser(responseGetDataUser.data);
  };

  useEffect(() => {
    if (cookies.departmentId !== "undefined") {
      getDataRatingAll(datePeriod);
      getDataUser();
    }
  }, [selectedUser, selectedDepartment]);

  console.log("data user", dataUser);

  useEffect(() => {
    setSelectedUser(null);
  }, [selectedDepartment]);

  const handleDatePeriod = (val) => {
    setDatePeriod(val);
    getDataRatingAll(val);
    getDataUser(val);
  };

  return (
    <Card className="card-full " style={{ height: "180px" }}>
      <div className="nk-ecwg nk-ecwg8 h-100 d-flex flex-column">
        <div className="card-inner h-100 d-flex flex-column" style={{ flex: 1 }}>
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Your Rating</h6>
            </div>
            <div className="card-tools-wrapper">
              <div className="card-tools">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="link link-light link-sm dropdown-indicator"
                  >
                    {datePeriod}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-sm">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod("DAILY");
                          }}
                          className={datePeriod === "DAILY" ? "active" : ""}
                        >
                          <span>Daily</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod("WEEKLY");
                          }}
                          className={datePeriod === "WEEKLY" ? "active" : ""}
                        >
                          <span>Weekly</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod("MONTHLY");
                          }}
                          className={datePeriod === "MONTHLY" ? "active" : ""}
                        >
                          <span>Monthly</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDatePeriod("YEARLY");
                          }}
                          className={datePeriod === "YEARLY" ? "active" : ""}
                        >
                          <span>Yearly</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="card-tools">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="link link-light link-sm dropdown-indicator"
                  >
                    {selectedUser ? `${selectedUser?.firstName} ${selectedUser?.lastName}` : "Select"}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-sm">
                    <ul className="link-list-opt-scrollable no-bdr">
                      {dataUser ? (
                        dataUser.map((user, index) => (
                          <li key={index}>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleSelectUser(user);
                              }}
                              className={selectedUser && selectedUser.id === user.id ? "active" : ""}
                            >
                              <span>{`${user.firstName} ${user.lastName}`}</span>
                            </DropdownItem>
                          </li>
                        ))
                      ) : (
                        <li>
                          <DropdownItem tag="a" href="#dropdown">
                            <span>None</span>
                          </DropdownItem>
                        </li>
                      )}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="report-panel">
            <h3 className="report-panel-title">
              {data?.currentTotal === null ? "0" : parseFloat(data?.currentTotal).toFixed(1)} / 5
            </h3>
            <div className="text-right">
              {percentage >= 0 ? (
                <div className="report-panel__trend__up">
                  <Icon name="upword-alt-fill" />
                  <span>{percentage}%</span>
                </div>
              ) : (
                <div className="report-panel__trend__down">
                  <Icon name="downward-alt-fill" />
                  <span>{Math.abs(percentage)}%</span>
                </div>
              )}
              <p>
                {`Than 
                  ${
                    datePeriod === "DAILY"
                      ? "Yesterday"
                      : datePeriod === "WEEKLY"
                      ? "Last Week"
                      : datePeriod === "MONTHLY"
                      ? "Last Month"
                      : datePeriod === "YEARLY"
                      ? "Last Year"
                      : null
                  }
                  : ${data?.lastTotal === null ? "0" : parseFloat(data?.lastTotal).toFixed(1)}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default RatingSales;
