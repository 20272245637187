import axios from "axios";
import { axiosPrivate } from "../api/axiosCore";

const limit = 20;
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const updateChatAdminReadCount = async (token, chatId, unreadCountAdmin) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put(
      "api/chats/" + chatId,
      JSON.stringify({ unreadCountAdmin: unreadCountAdmin })
    );
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const updateChatReadCount = async (token, chatId, unreadCount) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put("api/chats/" + chatId, { unreadCount: unreadCount });
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutChat = async (token, chatId, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put("api/chats/" + chatId, data);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetChats = async (token, userId, departmentId, status, tagId, roleFunctions) => {
  const basicUrl = `api/chats?take=${limit}&order=DESC`;
  axiosPrivate.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
  let paramStatus = "";
  let paramTagId = "";
  if (status !== "") {
    paramStatus = "&status=" + status;
  }
  if (tagId !== "" && tagId !== undefined) {
    paramTagId = "&tagIds=" + tagId;
  }
  let response = null;

  if (roleFunctions.includes(ALL_DEPT)) {
    response = await axiosPrivate.get(basicUrl + paramStatus + paramTagId);
  } else if (roleFunctions.includes(OWN) && roleFunctions.includes(OWN_DEPT)) {
    response = await axiosPrivate.get(basicUrl + `&userId=${userId}&departmentIds=${departmentId}` + paramStatus + paramTagId); //deptId
  } else if (roleFunctions.includes(OWN_DEPT)) {
    response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + paramStatus + paramTagId); //deptId
  } else if (roleFunctions.includes(OWN)) {
    response = await axiosPrivate.get(basicUrl + `&userId=${userId}&departmentIds=${departmentId}` + paramStatus + paramTagId);
  }
  return response;
};

export const serviceGetChatsOld = async (token, userId, departmentId, status, tagId, roleFunctions) => {
  const basicUrl = `api/chats?take=${limit}&order=DESC`;
  axiosPrivate.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
  let paramStatus = "";
  let paramTagId = "";
  if (status !== "") {
    paramStatus = "&status=" + status;
  }
  if (tagId !== "" && tagId !== undefined) {
    paramTagId = "&tagIds=" + tagId;
  }
  let response = null;

  if (roleFunctions.includes(ALL_DEPT)) {
    response = await axiosPrivate.get(basicUrl + paramStatus + paramTagId);
  } else if (roleFunctions.includes(OWN_DEPT)) {
    response = await axiosPrivate.get(
      `api/chats/?departmentId=${departmentId}&take=${limit}&order=DESC` + paramStatus + paramTagId
    ); //deptId
  } else if (roleFunctions.includes(OWN)) {
    response = await axiosPrivate.get(basicUrl + paramStatus + "&userId=" + userId + paramTagId);
  }
  return response;
};

export const serviceGetChatsByAdminStatus = async (token, userId, departmentId, status, tagId, roleFunctions) => {
  const basicUrl = `api/chats?take=${limit}&order=DESC`;
  axiosPrivate.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
  let paramStatus = "";
  let paramTagId = "";
  if (status !== "") {
    paramStatus = "&adminStatus=" + status;
  }
  if (tagId !== "" && tagId !== undefined) {
    paramTagId = "&tagIds=" + tagId;
  }
  let response = null;
  if (roleFunctions.includes(ALL_DEPT)) {
    response = await axiosPrivate.get(basicUrl + paramStatus + paramTagId);
  } else if (roleFunctions.includes(OWN_DEPT)) {
    response = await axiosPrivate.get(basicUrl + `&departmentIds=${departmentId}` + paramStatus + paramTagId); //deptId
  } else if (roleFunctions.includes(OWN)) {
    response = await axiosPrivate.get(basicUrl + paramStatus + "&userId=" + userId + paramTagId);
  }
  console.log(basicUrl);
  return response;
};

export const serviceGetChatsPage = async (token, userId, departmentId, status, tagId, page, roleFunctions) => {
  try {
    const basicUrl = `api/chats?take=${limit}&page=${page || 1}&order=DESC`;
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let paramStatus = "";
    let paramTagId = "";
    if (status !== "") {
      paramStatus = "&status=" + status;
    }
    if (tagId !== "" && tagId !== undefined) {
      paramTagId = "&tagIds=" + tagId;
    }
    let response = null;
    console.log(basicUrl, roleFunctions);
    if (roleFunctions.includes(ALL_DEPT)) {
      response = await axiosPrivate.get(basicUrl + paramStatus + paramTagId);
    } else if (roleFunctions.includes(OWN_DEPT)) {
      response = await axiosPrivate.get(basicUrl + paramStatus + "&departmentId=" + departmentId + paramTagId); //deptId
    } else if (roleFunctions.includes(OWN)) {
      response = await axiosPrivate.get(basicUrl + paramStatus + "&userId=" + userId + paramTagId);
    }
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetChatById = async (token, chatId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.get("api/chats/" + chatId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutAssignChat = async (token, chatId, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put("api/chats/" + chatId, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutAssignTag = async (token, chatId, chatTags) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put("api/chats/tags", chatTags);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceResetTag = async (token, chatId, chatTags) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const response = await axiosPrivate.put("api/chats/tags/reset", chatTags);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

/*

export const serviceGetChatsByName = async (token, userId, status, name,roleFunctions) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let paramStatus = "";
    if(status !== ""){
      paramStatus = "&status="+status;
    }
    let paramName = "";
    if(name !== ""){
      paramName = `&q=name like '%${name}%'`;
    }
    const response = await axiosPrivate.get("api/chats?take=15&order=DESC" + paramStatus +"&userId="+userId + paramName);
    
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetChatsByText = async (token, userId, status, text,roleFunctions) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let paramStatus = "";
    if(status !== ""){
      paramStatus = "&status="+status;
    }
    let paramText = "";
    if(text !== ""){
      paramText = `&q=text like '%${text}% and user_id='${userId}'`;
    }
    const response = await axiosPrivate.get("api/messages?take=15&order=DESC" + paramStatus + paramText);
    
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
*/
