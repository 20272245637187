import moment from "moment";
import { axiosPrivate } from "../api/axiosCore";
import { axiosCoreAstro } from "../api/axiosCoreAstro";
// import { Cookies } from "react-cookie";

export const getRating = async (token, datePeriod, cardType, departmentId, userId, role) => {
  const basicUrl = `api/dashboards/ticket-total`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    
    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1
    
    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}`);
    } else if(userId && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&userId=${userId}&departmentIds=${departmentId}`);
    } else if(userId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&userId=${userId}`);
    } else if(departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTotal = async (token, datePeriod, cardType, departmentId, userId, role) => {
  const basicUrl = `api/dashboards/ticket-total`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1

    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}&userId=${userId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTicketSystem = async (token, datePeriod, departmentId, role) => {
  const basicUrl = `api/dashboards/ticket-statistic`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1

    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTotalSalesTarget = async (token, datePeriod) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/dashboards/total-sales-target`;
    let response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getAstroTotalSalesTarget = async (token, pc, year, month) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/crm/target/${pc}/${year}/${month}`;
    let response = await axiosCoreAstro.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardLeadConversion = async (token, departmentId, datePeriod, role) => {
  const basicUrl = `api/dashboards/lead-conversion-rate`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1

    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTopTraffic = async (token, departmentId, startDate, endDate, role) => {
  const basicUrl = `api/dashboards/top-traffic-source`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1

    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCustomerLeadProspect = async (sort, token, page, take, salesId, startDate, endDate, role, departmentId, type) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/dashboards/customer-lead-prospect`;
    let response = "";
    if (type && (role === "SALES" || role === "CS")) {
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&salesId=${salesId}&type=${type}&startDate=${startDate}&endDate=${endDate}&fo=lastMessage.createdAt`
      );
    } else if (!type && (role === "SALES" || role === "CS")){
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&salesId=${salesId}&startDate=${startDate}&endDate=${endDate}&fo=lastMessage.createdAt`
      );
    } else if (type && (role === "HEAD")) {
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&type=${type}&startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&fo=lastMessage.createdAt`
      );
    } else if (!type && (role === "HEAD")){
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&fo=lastMessage.createdAt`
      );
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCampaignOverview = async (token, departmentId, startDate, endDate, campaignId, role) => {
  const basicUrl = `api/dashboards/campaign-overview`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1

    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCampaignOverviewTotal = async (token, departmentId, startDate, endDate, cardType, campaignId, role) => {
  const basicUrl = `api/dashboards/campaign-overview-total`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1
    
    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentList = async (token, roleFunctions, departmentIds) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/departments`;
    let response = null;
    if (roleFunctions.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(basicUrl);
    } else{
      response = await axiosPrivate.get(`${basicUrl}?departmentIds=${departmentIds}`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
