/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { QuillComponentMinimal } from "components/partials/rich-editor/QuillComponent";

import WhatsappImage from "../../../images/WhatsAppChat.png";
import { serviceGetChatbot, servicePutChatbot, serviceStoreMediaChatbot } from "services/chatbot";
import { getChannels } from "services/channel";

const triggerTypeOptions = [
  { label: "First Chat", value: "FIRST_CHAT" },
  { label: "Opening Chat", value: "OPENING_CHAT" },
  { label: "Text", value: "TEXT" },
  { label: "Button", value: "BUTTON" },
];

const headerTypeOptions = [
  { label: "None", value: "NONE" },
  { label: "Media", value: "MEDIA" },
  { label: "Text", value: "TEXT" },
];

const activeOptions = [
  { label: "OFF", value: 0 },
  { label: "ON", value: 1 },
];

const ruleButtonTypeOptions = [
  { label: "None", value: "NONE" },
  { label: "Button", value: "QUICK_REPLY" },
  { label: "Text", value: "TEXT" },
  { label: "List", value: "LIST" },
];

const EditChatbot = ({ ...props }) => {
  const { errors, register, handleSubmit, getValues, setValue } = useForm();
  const [cookies, setCookie] = useCookies(["user"]);
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);
  const [ruleBody, setRuleBody] = useState("");
  const [data, setData] = useState({});
  const [urlMediaHeader, setUrlMediaHeader] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [headerText, setHeaderText] = useState("");
  const [variableContent, setVariableContent] = useState("");
  const formDatas = getValues();
  const { id } = useParams();
  const [headerTypeState, setHeaderTypeState] = useState({
    label: "None",
    value: "NONE",
  });
  const [activeState, setActiveState] = useState({ label: "ON", value: 1 });
  const [buttonTypeState, setButtonTypeState] = useState({
    label: "None",
    value: "NONE",
  });
  const [triggerTypeState, setTriggerTypeState] = useState({
    label: "First Chat",
    value: "FIRST_CHAT",
  });
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("01:00");
  const [selectedChannel, setSelectedChannel] = useState();

  const [formData, setFormData] = useState({
    ruleBody: "",
    ruleDescription: "",
    ruleName: "",
    ruleTemplateType: "",
    ruleButtonType: "",
    headerType: "",
    headerMediaUrl: "",
    headerText: "",
    status: 1,
    chatTrigger: {
      ruleId: "",
      triggerType: "",
      triggerValue: "",
    },
    buttonRules: [
      {
        actionType: "",
        ruleId: "",
        ruleButtonId: "",
        buttonText: "",
        buttonDescription: "",
        buttonUrl: null,
      },
    ],
  });

  const [buttonRules, setButtonRules] = useState([]);
  const [channel, setChannel] = useState([]);

  const onFormSubmit = async (dt) => {
    // if (!fromTime || !toTime) {
    //   Swal.fire("Date Picker Kosong", "Harap isi kedua date picker", "error");
    //   return;
    // }

    let fRuleBody = ruleBody;
    fRuleBody = fRuleBody.replace(/<p>/g, "\n").replace(/<\/p>/g, "");
    fRuleBody = fRuleBody.replace(/<br>/g, "");
    fRuleBody = fRuleBody.replace(/^\n/, "");

    const submittedData = {
      id: id,
      ruleBody: fRuleBody,
      ruleDescription: "",
      ruleName: dt.ruleName || data.ruleName,
      ruleTemplateType: dt?.ruleTemplateType || data.ruleTemplateType,
      ruleButtonType: buttonTypeState.value || data.ruleButtonType,
      headerType: headerTypeState.value || data.headerType,
      headerMediaUrl: urlMediaHeader || "",
      headerText: dt.headerText || data.headerText,
      status: activeState.value,
      fromTime: fromTime ? getHourByDate(fromTime) : "00:00",
      toTime: toTime ? getHourByDate(toTime) : "00:00",
      buttonRules: buttonRules,
      chatTrigger: {
        triggerType: triggerTypeState.value,
        triggerValue: dt?.triggerValue || "",
        ruleId: id,
        departmentId: selectedChannel.value.deptID,
        channelId: selectedChannel.value.id,
      },
      channel: {
        id: selectedChannel.value.id,
        name: selectedChannel.label,
      },
      departmentId: selectedChannel.value.deptID,
      channelId: selectedChannel.value.id,
    };

    if (submittedData.ruleTemplateType === "REGULER") {
      submittedData.fromTime = null;
      submittedData.toTime = null;
    }

    if (submittedData.channel.id === undefined) {
      Swal.fire("Channel Kosong", `Harap masukkan channel`, "error");
      return;
    }

    if (submittedData.headerType === "MEDIA" && submittedData.ruleButtonType === "LIST") {
      Swal.fire("Add Chatbot Failed", `Media headers are only supported for Button Type chatbots`, "error");
      return;
    } else if (submittedData.headerType === "MEDIA" && submittedData.ruleButtonType === "TEXT") {
      Swal.fire("Add Chatbot Failed", `Media headers are only supported for Button Type chatbots`, "error");
      return;
    }

    if (
      submittedData.ruleButtonType === "LIST" &&
      submittedData.headerType === "TEXT" &&
      submittedData.headerText.length === 0
    ) {
      Swal.fire("Header Text Kosong", `Harap masukkan headerText`, "error");
      return;
    }
    if (
      submittedData.ruleButtonType === "QUICK_REPLY" &&
      submittedData.headerType === "TEXT" &&
      submittedData.headerText.length === 0
    ) {
      Swal.fire("Header Text Kosong", `Harap masukkan headerText`, "error");
      return;
    }

    if (
      submittedData.ruleButtonType === "QUICK_REPLY" &&
      submittedData.headerType === "MEDIA" &&
      submittedData.headerMediaUrl === ""
    ) {
      Swal.fire("Header Text Kosong", `Harap masukkan headerMedia`, "error");
      return;
    }

    if (
      submittedData.ruleButtonType === "LIST" &&
      submittedData.headerType === "TEXT" &&
      submittedData.headerText.length > 60
    ) {
      Swal.fire("Header Text Terlalu Panjang", `Maximal panjang dari header text adalah 60 karakter`, "error");
      return;
    }
    if (
      submittedData.ruleButtonType === "QUICK_REPLY" &&
      submittedData.headerType === "TEXT" &&
      submittedData.headerText.length > 60
    ) {
      Swal.fire("Header Text Terlalu Panjang", `Maximal panjang dari header text adalah 60 karakter`, "error");
      return;
    }

    if (submittedData.ruleButtonType === "TEXT" && submittedData.headerType === "TEXT") {
      Swal.fire("Ganti Button Type", `Button type yang anda pilih tidak bisa menambahkan header`, "error");
      return;
    }

    if (submittedData.ruleButtonType === "NONE" && submittedData.headerType !== "NONE") {
      Swal.fire("Ganti Button Type", `Button type yang anda pilih tidak bisa menambahkan header`, "error");
      return;
    }

    if (submittedData.chatTrigger.triggerType === "FIRST_CHAT") {
      submittedData.chatTrigger.triggerType = "FIRST_CHAT";
      submittedData.chatTrigger.triggerValue = "";
    }
    if (submittedData.headerType === "NONE") {
      submittedData.headerMediaUrl = "";
      submittedData.headerText = "";
    } else if (submittedData.headerType === "TEXT") {
      submittedData.headerMediaUrl = "";
    } else if (submittedData.headerType === "MEDIA") {
      submittedData.headerText = "";
    }

    if (buttonRules.length === 0) {
      delete submittedData["buttonRules"];
    }
    // return;
    const resp = await servicePutChatbot(cookies.SIDikey, id, submittedData);
    if (/20+/.test(resp.status)) {
      Swal.fire("Edit Chatbot Rule", "Berhasil", "success");
      setTimeout(() => {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/chat-bot"}`,
          "chat-bot",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/chat-bot"}`
        );
        window.location.reload();
      }, 1000);
    } else if (resp.status === 400) {
      if (resp.data.message === "Jam tersebut sudah ada dalam rule lain, silahkan pilih jam lain") {
        Swal.fire("Gagal Add Chatbot Rule", "Jam tersebut sudah ada dalam rule lain, silahkan pilih jam lain", "error");
      } else if (resp.data.message === "Name rule dengan channel yang sama sudah ada didatabase") {
        Swal.fire("Gagal Add Chatbot Rule", "Name rule dengan channel yang sama sudah ada didatabase", "error");
      } else if (resp.data.message === "Trigger value tersebut sudah ada dalam rule lain, silahkan pilih value lain") {
        Swal.fire(
          "Gagal Add Chatbot Rule",
          "Trigger value tersebut sudah ada dalam rule lain, silahkan pilih value lain",
          "error"
        );
      }
    } else {
      Swal.fire("Gagal Edit Chatbot Rule", "Periksa kembali isian", "error");
    }
    console.log(resp);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = async function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 1) {
        Swal.fire("Gagal upload gambar", "Hanya boleh drop satu gambar", "error");
        return;
      }
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          const fileType = file["type"];
          const validImageTypes = ["image/jpeg", "image/png"];

          if (!validImageTypes.includes(fileType)) {
            Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
            return;
          }

          const imageToSend = { file: file };
          const resp = await serviceStoreMediaChatbot(cookies.SIDikey, imageToSend);
          if (/20+/.test(resp.status)) {
            Swal.fire("Upload gambar", "Berhasil", "success");
            setValue("headerMedia", resp.data.url);
            setHeaderText("");
            setValue("headerText", "");
            setUrlMediaHeader(resp.data.url);
            setPreviewImage(URL.createObjectURL(file));
          } else if (resp.status === 400) {
            Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
          } else {
            Swal.fire("Gagal upload gambar", "Periksa kembali isian", "error");
          }
        }
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 1) {
      Swal.fire("Gagal upload gambar", "Hanya satu gambar yang dapat diupload", "error");
      return;
    }

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
        return;
      }

      const imageToSend = { file: file };

      const resp = await serviceStoreMediaChatbot(cookies.SIDikey, imageToSend);
      if (/20+/.test(resp.status)) {
        Swal.fire("Upload gambar", "Berhasil", "success");
        setValue("headerMedia", resp.data.url);
        setHeaderText("");
        setValue("headerText", "");
        setUrlMediaHeader(resp.data.url);
        setPreviewImage(URL.createObjectURL(file));
      } else if (resp.status === 400) {
        Swal.fire("Gagal upload gambar", "Mohon pilih format media (jpeg, png)", "error");
      } else {
        Swal.fire("Gagal upload gambar", "Periksa kembali isian", "error");
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const onDeleteQuickreplyButotn = (e, i) => {
    e.preventDefault();
    let tempArray = buttonRules;
    setButtonRules(tempArray.filter((obj) => obj !== i));
    setValue(
      "quickReplyproperties",
      formDatas.quickReplyproperties.filter((obj) => obj !== i)
    );
  };
  const onInputQuickReplyButtonTextChange = (event, index) => {
    event.preventDefault();
    let newInput = buttonRules;
    newInput[index].buttonText = event.target.value;
    setButtonRules([...newInput]);
  };

  const onInputQuickReplyButtonDescChange = (event, index) => {
    event.preventDefault();
    let newInput = buttonRules;
    newInput[index].buttonDescription = event.target.value;
    setButtonRules([...newInput]);
  };

  useEffect(() => {
    register("ruleBody");
    setValue("ruleBody", ruleBody);
  }, [ruleBody]);

  const getChannelList = useCallback(async () => {
    const channelRes = await getChannels(cookies.SIDikey);
    const roleFunctions = JSON.parse(localStorage.getItem("permission"))["chat-rules"].access || [];
    let channelsData = channelRes.data.data;
    if (!roleFunctions.includes("Access_All_Dept")) {
      channelsData = channelRes.data.data.filter((itm) =>
        cookies.departmentIds.some((dept) => itm.departmentId === dept)
      );
    }
    setChannel(
      channelsData.map((channel) => {
        return {
          label: channel.name,
          value: { id: channel.id, deptID: channel.departmentId },
        };
      })
    );
  });

  useEffect(() => {
    refreshData();
    getChannelList();
  }, []);

  const getTodayByHour = (timeString) => {
    if (timeString instanceof Date) {
      return timeString;
    }
    const today = new Date();
    const hours = timeString.split(":")[0];
    const minutes = timeString.split(":")[1];
    today.setHours(hours, minutes, 0, 0);

    return today;
  };

  const getHourByDate = (date) => {
    if (!(date instanceof Date)) {
      return date;
    }

    const updatedHour = String(date.getHours()).padStart(2, "0");
    const updatedMinute = String(date.getMinutes()).padStart(2, "0");

    return `${updatedHour}:${updatedMinute}`;
  };

  const refreshData = async () => {
    const resp = await serviceGetChatbot(cookies.SIDikey, id);
    if (resp.status === 200) {
      setData(resp.data);
      setRuleBody(resp.data.ruleBody);
      setVariableContent(resp.data.ruleBody);
      setPreviewImage(resp.data.buttonRules.length === 0 ? "" : `blob:${resp.data.headerMediaUrl}`);
      setUrlMediaHeader(resp.data.buttonRules.length === 0 ? "" : resp.data.headerMediaUrl);
      setHeaderText(resp.data.buttonRules.length === 0 ? "" : resp.data.headerText);
      setButtonRules(resp.data.buttonRules);
      setHeaderTypeState(
        resp.data.buttonRules.length === 0
          ? { label: "None", value: "NONE" }
          : headerTypeOptions.find((ht) => ht.value === resp.data.headerType) || { label: "None", value: "NONE" }
      );
      setButtonTypeState(
        ruleButtonTypeOptions.find((ht) => ht.value === resp.data.ruleButtonType) || { label: "None", value: "NONE" }
      );
      setTriggerTypeState(
        triggerTypeOptions.find((ta) => ta.value === resp.data?.chatTrigger?.triggerType) || {
          label: "None",
          value: "NONE",
        }
      );
      setActiveState(activeOptions.find((ht) => ht.value === resp.data.status));
      setFromTime(resp.data.fromTime ? getTodayByHour(resp.data.fromTime) : getTodayByHour("00:00"));
      setToTime(resp.data.toTime ? getTodayByHour(resp.data.toTime) : getTodayByHour("01:00"));
      setSelectedChannel(
        resp.data.channel
          ? {
              label: resp.data.channel.name,
              value: {
                id: resp.data.channel.id,
                deptID: resp.data.departmentId,
              },
            }
          : {
              label: "None",
              value: { id: null, deptID: resp.data.departmentId },
            }
      );
    }
  };

  const handleClick = () => {
    if (buttonRules.length === 0) {
      Swal.fire({
        title: "Chatbot Rule Info",
        text: "Tambahkan Child Terlebih Dahulu Baru Bisa Menambahkan Header",
        icon: "info",
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Add Chatbot Rule" />
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Chatbot Rule
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block>
          <Card className="p-4">
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <strong style={{ fontSize: 20, color: "#4461F2", fontWeight: 600 }}>Edit Chatbot Rule</strong>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block className="mt-4">
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="7" lg="7">
                  <FormGroup>
                    <label className="form-label">Nama Rule / Nama Display</label>
                    <input
                      className="form-control"
                      type="text"
                      name="ruleName"
                      ref={register({
                        required: "Mohon tambahkan ruleName",
                      })}
                      defaultValue={data.ruleName}
                      placeholder="Nama rule yang akan ditampikan di sistem"
                    ></input>
                    {errors.ruleName && <span className="invalid">{errors.ruleName.message}</span>}
                  </FormGroup>
                  {data.ruleTemplateType === "MASTER" && (
                    <FormGroup>
                      <label className="form-label">Channel</label>
                      <div className="row gy-4">
                        <Col md="3">
                          <RSelect
                            onChange={(e) => {
                              setValue("channel", e.value);
                              setSelectedChannel(e);
                            }}
                            name="channel"
                            options={channel}
                            value={selectedChannel ? selectedChannel : ""}
                          />
                        </Col>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <label className="form-label">Tipe Template</label>
                    <div className="d-flex ">
                      <label htmlFor="master" className="d-flex align-items-center">
                        <input
                          name="ruleTemplateType"
                          id="master"
                          type="radio"
                          ref={register({
                            required: false,
                          })}
                          value="MASTER"
                          checked={data.ruleTemplateType === "MASTER"}
                          // onChange={()=>{
                          //   const temp = data;
                          //   temp.ruleTemplateType = "MASTER";
                          //   setData(temp);
                          // }}
                        />
                        <p className="ml-1"> Master Template</p>
                      </label>
                      <label htmlFor="reguler" className="d-flex align-items-center ml-3">
                        <input
                          name="ruleTemplateType"
                          id="reguler"
                          type="radio"
                          ref={register({
                            required: false,
                          })}
                          value="REGULER"
                          checked={data.ruleTemplateType === "REGULER"}
                          // onChange={()=>{
                          //   const temp = data;
                          //   temp.ruleTemplateType = "REGULER";
                          //   setData(temp);
                          // }}
                        />
                        <p className="ml-1"> Reguler Template</p>
                      </label>
                    </div>
                  </FormGroup>
                  {/* Time Range */}
                  {data.ruleTemplateType === "MASTER" && (
                    <FormGroup>
                      <label className="form-label">Waktu Aktif</label>
                      <div className="form-control-wrap">
                        <div className="input-daterange date-picker-range input-group">
                          <DatePicker
                            selected={getTodayByHour(fromTime)}
                            onChange={(date) => setFromTime(getHourByDate(date) || "00:00")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Waktu Mulai"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className="form-control"
                            wrapperClassName="start-m"
                          />{" "}
                          <div className="input-group-addon">TO</div>
                          <DatePicker
                            selected={getTodayByHour(toTime)}
                            onChange={(date) => setToTime(getHourByDate(date) || "00:00")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Waktu Sampai"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className="form-control"
                            wrapperClassName="end-m"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <label className="form-label">Trigger</label>
                    <div className="row gy-4">
                      <Col md="3">
                        <RSelect
                          onChange={(e) => {
                            setValue("triggerType", e.value);
                            setTriggerTypeState(e);
                          }}
                          name="triggerType"
                          options={triggerTypeOptions}
                          value={triggerTypeState}
                        />
                      </Col>
                      <>
                        {/* <Col md="2">
                              <RSelect
                                onChange={(e) => {
                                  onTriggerSubtypeSelected(e);
                                  setValue("triggerType", e.value);
                                }}
                                ref={register("triggerType", {
                                  required: true,
                                })}
                                name="triggerType"
                                options={triggerSubOptions}
                              />
                            </Col> */}
                        {triggerTypeState.value === "OPENING_CHAT" && (
                          <Col md="7">
                            <input
                              name="triggerValue"
                              className="form-control"
                              type="text"
                              ref={register({
                                required: true,
                              })}
                              defaultValue={data?.chatTrigger?.triggerValue}
                              placeholder="Teks trigger / triggerValue"
                            />
                            {errors.triggerValue && <span className="invalid">This field is required</span>}
                          </Col>
                        )}
                      </>
                    </div>
                  </FormGroup>
                  <FormGroup onClick={handleClick}>
                    <label className="form-label">
                      Response Header{" "}
                      <span
                        className="badge rounded-pill text-bg-primary"
                        style={{
                          marginLeft: "4px",
                          background: "#ADADAD",
                          color: "white",
                          border: "none",
                          padding: "2px 10px",
                        }}
                      >
                        Optional
                      </span>
                    </label>
                    <p className="form-subtitle">
                      Tambah judul atau pilih tipe dari media yang akan digunakan pada judul ini.
                    </p>
                    {buttonTypeState.value === "TEXT" && headerTypeState.value !== "NONE" && (
                      <div style={{ color: "red" }}>
                        *button type {buttonTypeState.value} tidak bisa menambahkan header
                      </div>
                    )}
                    {buttonTypeState.value === "NONE" && headerTypeState.value !== "NONE" && (
                      <div style={{ color: "red" }}>
                        *button type {buttonTypeState.value} tidak bisa menambahkan header
                      </div>
                    )}
                    {buttonTypeState.value === "LIST" && headerTypeState.value === "MEDIA" && (
                      <div style={{ color: "red" }}>
                        *button type {buttonTypeState.value} tidak bisa menambahkan header media
                      </div>
                    )}
                    <div className="row gy-4">
                      <Col md="3">
                        <div
                          style={{
                            border:
                              buttonTypeState.value === "NONE" && headerTypeState.value !== "NONE"
                                ? "1px solid red"
                                : "",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              border:
                                buttonTypeState.value === "TEXT" && headerTypeState.value !== "NONE"
                                  ? "1px solid red"
                                  : "",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                border:
                                  buttonTypeState.value === "LIST" && headerTypeState.value === "MEDIA"
                                    ? "1px solid red"
                                    : "",
                                borderRadius: "5px",
                              }}
                            >
                              <RSelect
                                onChange={(e) => {
                                  setValue("headerType", buttonRules.length !== 0 ? "None" : e.value);
                                  setHeaderTypeState(e);
                                }}
                                value={headerTypeState}
                                options={headerTypeOptions}
                                disabled={buttonRules.length === 0 ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      {buttonRules.length !== 0 && headerTypeState.value && headerTypeState.value === "TEXT" && (
                        <Col md="9">
                          <input
                            defaultValue={headerText}
                            name="headerText"
                            className="form-control"
                            type="text"
                            ref={register({
                              required: false,
                            })}
                            style={{
                              border:
                                buttonTypeState.value !== "QUICK_REPLY" && buttonTypeState.value !== "LIST"
                                  ? "1px solid red"
                                  : "",
                            }}
                            placeholder="Masukkan teks disini"
                            onChange={(e) => {
                              setValue("headerMedia", null);
                              setPreviewImage(null);
                              setHeaderText(e.target.value);
                            }}
                          />
                          {errors.headerText && <span className="invalid">{errors.headerText.message}</span>}
                        </Col>
                      )}
                    </div>
                    {buttonRules.length !== 0 &&
                      headerTypeState.value &&
                      buttonTypeState.value === "QUICK_REPLY" &&
                      headerTypeState.value === "MEDIA" && (
                        // (!formDatas.headerMedia ? (
                        <div className="mt-2" id="form-file-upload" onDragEnter={handleDrag}>
                          <input
                            ref={inputRef}
                            // ref={register("headerMedia")}
                            type="file"
                            id="input-file-upload"
                            multiple={true}
                            onChange={handleChange}
                          />
                          <label
                            id="label-file-upload"
                            htmlFor="input-file-upload"
                            className={dragActive ? "drag-active" : ""}
                          >
                            <div>
                              <p>Drag and drop your file here or</p>
                              <button className="upload-button" onClick={onButtonClick}>
                                Upload a file
                              </button>
                              <div>
                                {urlMediaHeader ? <img src={urlMediaHeader} alt="preveiwImage" width="100px" /> : ""}
                              </div>
                            </div>
                          </label>
                          {dragActive && (
                            <div
                              id="drag-file-element"
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </div>
                      )}
                    {headerTypeState.value &&
                      buttonTypeState.value === "LIST" &&
                      headerTypeState.value === "MEDIA" &&
                      null}
                    {headerTypeState.value &&
                      buttonTypeState.value === "TEXT" &&
                      headerTypeState.value === "MEDIA" &&
                      null}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Response Body</label>
                    {/* <QuillComponentMinimal setTextInput={setRuleBody} quillContent={variableContent} /> */}
                    {/* <textarea
                      className="form-control"
                      value={ruleBody}
                      onChange={(e)=>setRuleBody(e.value)}
                    /> */}
                    <QuillComponentMinimal setTextInput={setRuleBody} quillContent={variableContent} />
                  </FormGroup>
                  {/* <FormGroup>
                    <label className="form-label">
                      Tambah Variabel <Icon name="help-fill" style={{ color: "#ADADAD" }} />
                    </label>
                    <div className="variable_button_container">
                      <button
                        className="variable_button"
                        onClick={(e) => {
                          e.preventDefault();
                          addVariable("Email");
                        }}
                      >
                        + Email
                      </button>
                      <button
                        className="variable_button"
                        onClick={(e) => {
                          e.preventDefault();
                          addVariable("Name");
                        }}
                      >
                        + Name
                      </button>
                      <button
                        className="variable_button"
                        onClick={(e) => {
                          e.preventDefault();
                          addVariable("Phone Number");
                        }}
                      >
                        + Phone Number
                      </button>
                      <button
                        className="variable_button"
                        onClick={(e) => {
                          e.preventDefault();
                          addVariable("Record Speech");
                        }}
                      >
                        + Record Speech
                      </button>
                    </div>
                  </FormGroup> */}
                  <FormGroup>
                    <label className="form-label">Active Status</label>
                    <Col md="3">
                      <RSelect
                        onChange={(e) => {
                          setValue("status", e.value);
                          setActiveState(e);
                        }}
                        value={activeState}
                        options={activeOptions}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">
                      Buttons{" "}
                      <span
                        className="badge rounded-pill text-bg-primary"
                        style={{
                          marginLeft: "4px",
                          background: "#ADADAD",
                          color: "white",
                          border: "none",
                          padding: "2px 10px",
                        }}
                      >
                        Optional
                      </span>
                    </label>
                    <p className="form-subtitle">
                      Buat tombol sebagai respon pelanggan terhadap pesan anda atau tanggapan
                    </p>
                    <div className="row gy-4">
                      <Col md="3">
                        <RSelect
                          onChange={(e) => {
                            if (buttonRules.length > 0) {
                              Swal.fire(
                                "Caution",
                                "Mohon hapus buttons dibawah sebelum mengganti button type",
                                "error"
                              );
                            } else {
                              setButtonTypeState(e);
                            }
                          }}
                          value={buttonTypeState}
                          name="buttonsType"
                          options={ruleButtonTypeOptions}
                        />
                        {console.log(formDatas.buttonsType)}
                      </Col>
                    </div>
                    {data.ruleButtonType !== "NONE" ? (
                      data.ruleButtonType !== "NONE" ? (
                        <div className="button_options_container">
                          {buttonRules.length > 0 &&
                            buttonRules.map((item, idx) => {
                              return (
                                <div key={idx}>
                                  <fieldset className="row gy-4 quickreply_button_container">
                                    <Col md="1">{idx + 1}</Col>{" "}
                                    <Col md="3">
                                      <label className="form-label">Button Text</label>
                                      <div className="position-relative">
                                        <input
                                          onChange={(e) => {
                                            if (e.target.value.length <= 20) {
                                              onInputQuickReplyButtonTextChange(e, idx);
                                            }
                                          }}
                                          defaultValue={item.buttonText}
                                          className="form-control"
                                          type="text"
                                          maxLength="20"
                                        />
                                        <span
                                          className="position-absolute"
                                          style={{
                                            bottom: 6,
                                            right: 10,
                                            color: "#D9D9D9",
                                            background: "white",
                                          }}
                                        >
                                          /{Math.abs(buttonRules[idx].buttonText.length - 20)}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <label className="form-label">Button Description</label>
                                      <div className="position-relative">
                                        <input
                                          onChange={(e) => onInputQuickReplyButtonDescChange(e, idx)}
                                          className="form-control"
                                          defaultValue={item.buttonDescription}
                                          type="text"
                                        />
                                      </div>
                                    </Col>
                                    {/* <Col md="3">
                                    <label className="form-label">Direct Rule</label>
                                    <div className="position-relative">
                                      <RSelect
                                        onChange={(e) => onQuickReplyRuleSelected(e)}
                                        name="directRule"
                                        options={directRuleOptions}
                                      />
                                    </div>
                                  </Col> */}
                                    {buttonRules.length > 1 && (
                                      <Col md="2">
                                        <span
                                          style={{
                                            fontSize: 18,
                                            color: "#B9B9B9",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => onDeleteQuickreplyButotn(e, item)}
                                        >
                                          <Icon name="trash-empty-fill" />
                                        </span>
                                      </Col>
                                    )}
                                  </fieldset>
                                </div>
                              );
                            })}
                          {/* { (buttonTypeState.value === "QUICK_REPLY" && buttonRules.length < 4) && (
                            <button
                              className="another_button"
                              onClick={(e) => {
                                e.preventDefault();
                                setButtonRules([
                                  ...buttonRules,
                                  {
                                    buttonText: "",
                                    buttonDescription: "",
                                    directRule: "",
                                    id: Math.floor(Math.random() * 9999) + 1,
                                  },
                                ]);
                              }}
                            >
                              + Add Another Button
                            </button>
                          )} */}
                        </div>
                      ) : (
                        <div className="button_options_container">
                          <div className="row gy-4">
                            {/* <Col md="4">
                              <label className="form-label">Type of Action</label>
                              <RSelect
                                onChange={(e) => onActionTypeSelected(e)}
                                name="typeOfActions"
                                options={typeOfActionOptions}
                                ref={register("typeOfActions")}
                              />
                            </Col> */}
                            <Col md="4">
                              <label className="form-label">Button Text</label>
                              <div className="position-relative">
                                <input
                                  onChange={(e) => {
                                    setValue("actionsButtonText", e.target.value);
                                    setFormData({
                                      ...formData,
                                      callToActionProperties: {
                                        ...formData.callToActionProperties,
                                        buttonText: e.target.value,
                                      },
                                    });
                                  }}
                                  name="buttonText"
                                  className="form-control"
                                  type="text"
                                  ref={register("actionsButtonText")}
                                />

                                <span
                                  className="position-absolute"
                                  style={{
                                    bottom: 6,
                                    right: 10,
                                    color: "#D9D9D9",
                                    background: "white",
                                  }}
                                >
                                  /{Math.abs(formData?.callToActionProperties?.buttonText?.length || 40 - 20)}
                                </span>
                              </div>
                            </Col>
                            {/* {formData.callToActionProperties.typeOfActions !== "none" ? ( */}
                            {false ? (
                              formData.callToActionProperties.typeOfActions === "url" ? (
                                <Col md="4">
                                  <label className="form-label">URL Link</label>
                                  <input
                                    ref={register("actionsUrlLink")}
                                    onChange={(e) => setValue("actionsUrlLink", e.target.value)}
                                    name="urlLink"
                                    className="form-control"
                                    type="url"
                                  />
                                </Col>
                              ) : (
                                <Col md="4">
                                  <label className="form-label">Phone Number</label>
                                  <input
                                    ref={register("actionsPhoneNumber")}
                                    onChange={(e) => setValue("actionsPhoneNumber", e.target.value)}
                                    name="urlLink"
                                    className="form-control"
                                    type="number"
                                  />
                                </Col>
                              )
                            ) : (
                              <Col md="4"></Col>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Col>
                <Col md="5" lg="5">
                  <div className="chatbot_preview_image_container">
                    <div className="chatbot_preview_image">
                      <p className="chatbot_preview_text">Preview</p>
                      <img src={WhatsappImage} alt="preview chatbot" />
                      <div className="chatbot_preview_container">
                        {formDatas.headerType !== "" && (
                          <div className="chatbot_welcome_preview">
                            {headerTypeState.value === "TEXT" ? (
                              <span className="chatbot_preview_header_text">{headerText}</span>
                            ) : headerTypeState.value === "MEDIA" ? (
                              urlMediaHeader ? (
                                <img src={urlMediaHeader} alt="previewImage" />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            <div
                              className="chatbot_preview_body_text"
                              dangerouslySetInnerHTML={{
                                __html: `${ruleBody}`,
                              }}
                            />
                          </div>
                        )}

                        <div className="chatbot_button_preview">
                          {buttonRules.map((item, idx) => (
                            <div className="chatbot_button_item" key={idx}>
                              {item.buttonText}
                            </div>
                          ))}
                        </div>
                        <div className="chatbot_button_preview">
                          {formDatas.actionsButtonText && (
                            <div className="chatbot_button_item chatbot_link_button">
                              {formDatas?.actionsButtonText}
                              <Icon name="external" className="ml-2" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col size="12">
                  <Link to={`${process.env.PUBLIC_URL + "/chat-bot"}`}>
                    <Button outline type="button" color="primary" className="mr-3">
                      Batal
                    </Button>
                  </Link>
                  <Button type="submit" color="primary">
                    Simpan
                  </Button>
                </Col>
              </Form>
            </Block>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default EditChatbot;
