import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { findUpper } from "../../utils/Utils";
import { userData, filterRole, filterStatus } from "../pre-built/user-manage/UserData";
import Swal from "sweetalert2";
import { UncontrolledTooltip, Badge } from "reactstrap";
import { serviceGetUsersByDeparmentId } from "../../services/users";

// import channelData from "./ChannelData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { ChannelContext } from "./ChannelContext";
import getTextColor from "../../components/custom/getTextColor";
import { useCookies } from "react-cookie";
import { getChannels, postChannel, putChannel, deleteChannel, getChannelsByField } from "services/channel";
import { getDepartments } from "services/departments";
import moment from "moment";

let channelData = [];

const Channels = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);

  const { contextData } = useContext(ChannelContext);
  const [data, setData] = contextData;
  const [metaData, setMetaData] = useState();

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });
  const [editContent, setEditContent] = useState(false);
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    id: "",
    waNumber: "",
    name: "",
    department: {
      id: "",
      label: "",
      color: "",
      value: "",
    },
    apiKey: "",
    verification: true,
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [dataDepartment, setDataDepartment] = useState([]);
  const [orderBy, setOrderBy] = useState("DESC");
  const [fieldSort, setFieldSort] = useState();
  const [selectedCpcOpt, setSelectedCpcOpt] = useState([]);
  const departmentSelected = async (e) => {
    const resGetUser = await serviceGetUsersByDeparmentId(cookies.SIDikey, e.id);
    const selectedCpc = resGetUser
      .filter((cpc) => cpc.isActive === true)
      .map((cpc) => {
        return {
          label: cpc.firstName + " " + cpc.lastName,
          name: cpc.firstName + " " + cpc.lastName,
          value: cpc.id,
        };
      });
    setSelectedCpcOpt(resGetUser && selectedCpc);

    setFormData({
      id: formData.id,
      waNumber: formData.waNumber,
      name: formData.name,
      department: e,
      cpc: "",
      apiKey: formData.apiKey,
      verification: formData.verification,
      createdAt: formData.createdAt,
      createdBy: formData.createdBy,
      updatedAt: formData.updatedAt,
      updatedBy: formData.updatedBy,
    });
  };

  const cpcSelected = (e) => {
    setFormData({
      id: formData.id,
      waNumber: formData.waNumber,
      name: formData.name,
      department: formData.department,
      cpc: e,
      apiKey: formData.apiKey,
      verification: formData.verification,
      createdAt: formData.createdAt,
      createdBy: formData.createdBy,
      updatedAt: formData.updatedAt,
      updatedBy: formData.updatedBy,
    });
  };

  const [numberValue, setNumberValue] = useState("");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["channels"].access || [];

  const numberHandleChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumberValue(result);
  };
  const refreshChannelsByField = async (field, pageNumber, order) => {
    const responseGetDepartments = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
    const respChannelsByField = await getChannelsByField(cookies.SIDikey, field, pageNumber, order);
    if (new RegExp(/20+/).test(respChannelsByField.status) & new RegExp(/20+/).test(responseGetDepartments.status)) {
      const channelData = respChannelsByField.data.data;
      const departmentData = responseGetDepartments.data.data;
      const finalChanneldata = [];
      const finalDepartmentData = [];
      let currentDepartment = [];

      // Menyaring data departemen sesuai dengan akses user
      if (roleFunctions.includes("Access_All_Dept")) {
        currentDepartment = departmentData; // Semua departemen
      } else {
        currentDepartment = departmentData.filter((dpt) => cookies.departmentIds.includes(dpt.id)); // Departemen yang sesuai dengan user
      }

      for (let departement of currentDepartment) {
        let createdBy = "";
        let updatedBy = "";

        if (departement.createdBy != null) {
          createdBy = departement.createdBy.firstName + " " + departement.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (departement.updatedBy != null) {
          updatedBy = departement.updatedBy.firstName + " " + departement.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempData = {
          id: departement.id,
          label: departement.name,
          name: departement.name,
          color: departement.color,
          value: departement.id,
          active: true,
        };
        finalDepartmentData.push(tempData);
      }

      for (let channel of channelData) {
        let createdBy = "";
        let updatedBy = "";

        if (!roleFunctions.includes("Access_All_Dept")) {
          if (!cookies.departmentIds.includes(channel.departmentId)) {
            continue;
          }
        }

        if (channel.createdBy != null) {
          createdBy = channel.createdBy.firstName + " " + channel.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (channel.updatedBy != null) {
          updatedBy = channel.updatedBy.firstName + " " + channel.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempDepartment = finalDepartmentData.filter((item) => {
          return item.id === channel.departmentId;
        });
        console.log("tempDepartment", tempDepartment);
        const tempData = {
          id: channel.id,
          waNumber: channel.phoneNumber,
          name: channel.name,
          departement: tempDepartment[0],
          verification: true,
          cs: 12,
          apiKey: formData.apiKey,
          createdAt: channel.createdAt,
          updatedAt: channel.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
        };
        finalChanneldata.push(tempData);
      }

      await Promise.all(
        channelData?.map(async (channel, idx) => {
          const resGetUser = await serviceGetUsersByDeparmentId(cookies.SIDikey, cookies.departmentIds);
          const currentIndex = finalChanneldata.findIndex((finalChannel) => finalChannel.id === channel.id);
          finalChanneldata[currentIndex].cpc = channel.cpc && resGetUser.filter((item) => item.id === channel.cpc);
          finalChanneldata[currentIndex].cpcOpt = resGetUser;
        })
      );

      setData(finalChanneldata);
      setDataDepartment(finalDepartmentData);
      refreshHeight();
      setMetaData(respChannelsByField.data.meta);
    } else {
      console.log("error");
    }
  };

  const refreshData = async (postPerPage = 10, page = 1) => {
    const responseGetDepartments = await getDepartments(
      cookies.SIDikey,
      "",
      cookies.departmentIds,
      cookies.id,
      roleFunctions
    );
    const responseGetChannels = await getChannels(cookies.SIDikey, page, postPerPage);

    if (new RegExp(/20+/).test(responseGetChannels.status) && new RegExp(/20+/).test(responseGetDepartments.status)) {
      const channelData = responseGetChannels.data.data;
      const departmentData = responseGetDepartments.data.data;
      const finalChanneldata = [];
      const finalDepartmentData = [];
      const finalUserData = [];

      let currentDepartment;
      if (roleFunctions.includes("Access_All_Dept")) {
        currentDepartment = departmentData;
      } else {
        currentDepartment = departmentData.filter((dpt) => cookies.departmentIds.includes(dpt.id));
      }

      for (let department of currentDepartment) {
        let createdBy = department.createdBy
          ? `${department.createdBy.firstName} ${department.createdBy.lastName}`
          : "-";
        let updatedBy = department.updatedBy
          ? `${department.updatedBy.firstName} ${department.updatedBy.lastName}`
          : "-";

        const tempData = {
          id: department.id,
          label: department.name,
          name: department.name,
          color: department.color,
          value: department.id,
          active: true,
        };
        finalDepartmentData.push(tempData);
      }

      for (let channel of channelData) {
        let createdBy = channel.createdBy ? `${channel.createdBy.firstName} ${channel.createdBy.lastName}` : "-";
        let updatedBy = channel.updatedBy ? `${channel.updatedBy.firstName} ${channel.updatedBy.lastName}` : "-";

        if (!roleFunctions.includes("Access_All_Dept")) {
          if (!cookies.departmentIds.includes(channel.departmentId)) {
            continue;
          }
        }

        const tempDepartment = finalDepartmentData.find((item) => item.id === channel.departmentId);

        const tempData = {
          id: channel.id,
          waNumber: channel.phoneNumber,
          name: channel.name,
          department: tempDepartment,
          verification: true,
          cs: 12,
          apiKey: channel.apiKey,
          createdAt: channel.createdAt,
          updatedAt: channel.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
        };

        finalChanneldata.push(tempData);
      }

      await Promise.all(
        channelData.map(async (channel) => {
          const tempDepartment = finalDepartmentData.find((item) => item.id === channel.departmentId);
          const deptId = roleFunctions.includes("Access_All_Dept") ? tempDepartment?.id : cookies.departmentIds;

          if (deptId) {
            const resGetUser = await serviceGetUsersByDeparmentId(cookies.SIDikey, deptId);
            const currentIndex = finalChanneldata.findIndex((finalChannel) => finalChannel.id === channel.id);

            if (currentIndex !== -1) {
              finalChanneldata[currentIndex].cpc = channel.cpc && resGetUser.find((item) => item.id === channel.cpc);
              finalChanneldata[currentIndex].cpcOpt = resGetUser;
            }
          }
        })
      );

      setData(finalChanneldata);
      setDataDepartment(finalDepartmentData);
      refreshHeight();
      setMetaData(responseGetChannels.data.meta);
    } else {
      console.log(responseGetDepartments);
    }
  };

  console.log("data", formData)

  const refreshDepartment = async () => {};

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    refreshDepartment();
    refreshData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current?.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
      });
      setNonFixedHeight(tempHeight);
    }
  };

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = channelData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...channelData]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to change the selected property of an item

  // function to set the action to be taken in table header

  // function to reset the form
  const resetForm = () => {
    setNumberValue("");
    setFormData({
      waNumber: "",
      name: "",
      apiKey: "",
      department: [],
      verification: true,
      createdAt: "",
      createdBy: "",
      updatedAt: "",
      updatedBy: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ detail: false, add: false });
    setEditContent(false);
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
    let { waNumber, name, apiKey } = submitData;

    if (waNumber[0] === "0") {
      waNumber = waNumber.substring(1);
    }
    waNumber = "62" + waNumber;
    let submittedData = {
      name: name,
      phoneNumber: waNumber,
      apiKey: apiKey,
      status: "Active",
      departmentId: formData.department.id,
      cpc: formData?.cpc?.value,
    };

    const responsePostUser = await postChannel(cookies.SIDikey, submittedData);

    if (!submittedData.departmentId || submittedData.departmentId === "") {
      Swal.fire({
        icon: "error",
        title: "Department Kosong Harap Diisi",
        focusConfirm: false,
      });
      return;
    }

    if (new RegExp(/20+/).test(responsePostUser.status)) {
      Swal.fire({
        icon: "success",
        title: "Added Channel Successfully",
        focusConfirm: false,
      }).then(() => {
        // setData([submittedData, ...data]);
        refreshData();
        resetForm();
        setModal({ detail: false }, { add: false });
        refreshHeight();
      });
    } else if (responsePostUser.status === 400) {
      Swal.fire({
        icon: "error",
        title: "Nomor Whatsapp Sudah Terpakai",
        focusConfirm: false,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Adding Channel",
        focusConfirm: false,
      });
    }
  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {
    let { waNumber, name, apiKey } = submitData;

    if (!formData.department) {
      return;
    }
    if (waNumber[0] === "0") {
      waNumber = waNumber.substring(1);
    }
    waNumber = "62" + waNumber;
    let submittedData = {
      name: name,
      phoneNumber: waNumber,
      apiKey: apiKey,
      status: "Active",
      departmentId: formData.department.id,
      cpc: formData?.cpc?.value,
    };

    const responsePostUser = await putChannel(editId, cookies.SIDikey, submittedData);

    if (new RegExp(/20+/).test(responsePostUser.status)) {
      Swal.fire({
        icon: "success",
        title: "Edit Channel Successfully",
        focusConfirm: false,
      }).then(() => {
        refreshData();
        // let index = data.findIndex((item) => item.id === editId);
        // data[index] = submittedData;
        setModal({ detail: false });
        setEditContent(false);
        refreshHeight();
      });
    } else if (responsePostUser.status === 400) {
      Swal.fire({
        icon: "error",
        title: "Nomor Whatsapp Sudah Terpakai",
        focusConfirm: false,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Edit Channel",
        focusConfirm: false,
      });
    }
  };

  const onDetailClick = (id) => {
    resetForm();

    const selectedItem = data.find((item) => item.id === id);

    if (selectedItem) {
      const selectedCpc = selectedItem.cpc
        ? {
            label: selectedItem.cpc.firstName + " " + selectedItem.cpc.lastName,
            name: selectedItem.cpc.firstName + " " + selectedItem.cpc.lastName,
            value: selectedItem.cpc.id,
          }
        : {
            label: "No CPC Selected",
            name: "No CPC Selected",
            value: null,
          };

      setFormData({
        id: selectedItem.id,
        waNumber: selectedItem.waNumber.replace("62", ""),
        name: selectedItem.name,
        department: selectedItem.department
          ? {
              label: selectedItem.department.name,
              name: selectedItem.department.name,
              color: selectedItem.department.color,
              value: selectedItem.department.id,
            }
          : null,
        callCenterProfit: selectedCpc,
        verification: true,
        apiKey: selectedItem.apiKey,
        createdAt: selectedItem.createdAt,
        createdBy: selectedItem.createdBy,
        updatedAt: selectedItem.updatedAt,
        updatedBy: selectedItem.updatedBy,
      });

      // Set additional values
      setNumberValue(selectedItem.waNumber.replace("62", ""));
      setModal({ detail: true }, { add: false });
      setEditedId(id);

      // Update CPC options
      setSelectedCpcOpt(
        selectedItem.cpcOpt?.map((cpc) => ({
          label: cpc.firstName + " " + cpc.lastName,
          name: cpc.firstName + " " + cpc.lastName,
          value: cpc.id,
        })) || []
      );
    } else {
      console.log(`No item found with id: ${id}`);
    }
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onDeleteClick = (id, name) => {
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteResponse = await deleteChannel(id, cookies.SIDikey);
        console.log("response", deleteResponse.status);

        if (new RegExp(/20+/).test(deleteResponse.status)) {
          Swal.fire({
            icon: "success",
            title: "Succesfull Transasction",
            focusConfirm: false,
          }).then(() => {
            refreshData();
            resetForm();
            setModal({ detail: false }, { add: false });
            setEditContent(false);
          });
        } else if (deleteResponse.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Channel Terpakai Di Page Lain",
            focusConfirm: false,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Unsuccesfull Transasction",
            focusConfirm: false,
          });
        }
      }
    });
  };

  const onSortClicked = (id, order) => {
    setOrderBy(order);
    setFieldSort(id);
    refreshChannelsByField(id, 1, order);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) =>
    fieldSort ? refreshChannelsByField(fieldSort, pageNumber, orderBy) : refreshData(10, pageNumber);
  const aksiOptions = [
    { value: "Aktif", label: "Aktif" },
    { value: "Non Aktif", label: "Non Aktif" },
  ];

  return (
    <React.Fragment>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Channels
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p> Do a scan QR to activate Whatsapp Multi Device on one number</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            {!roleFunctions.includes("create") || (
              <BlockHeadContent style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ width: "100px" }} className="toggle-wrap nk-block-tools-toggle">
                  {/* <RSelect
                    name="actionOption"
                    defaultValue={{ label: "Aktif", value: "Aktif" }}
                    options={aksiOptions}
                  /> */}
                </div>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                          <i className="ni ni-plus"></i>
                          <span>Tambah Baru</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            )}
          </Block>
        </BlockHead>

        <Block>
          <DataTable style={{ borderRadius: "10px" }} className="card-stretch">
            <Row>
              <Col sm="8" md="8" lg="4" xl="3" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">No. Whatsapp</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Name
                        <Button id="channelName" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="channelName">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow>
                              <span>{index + 1}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>+{item.waNumber}</span>
                            </DataTableRow>
                            <DataTableRow
                              noWrap
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                            >
                              <span>{item.name}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col sm="4" md="4" lg="8" xl="9" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Department
                      </div>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Profit Center Contact
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Aksi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Modify At
                        <Button id="channelModifyAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="channelModifyAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.updatedAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.updatedAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Modify By
                        {/* <Button id="channelModifyBy" size="xs">
                          <Icon name="sort" />
                        </Button> */}
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="channelModifyBy">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.updatedBy", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.updatedBy", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Created At
                        <Button id="channelCreatedAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="channelCreatedAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.createdAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.createdAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Created By
                        {/* <Button id="channelCreatedBy" size="xs">
                          <Icon name="sort" />
                        </Button> */}
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="channelCreatedBy">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.createdBy", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("channel.createdBy", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow ref={addToRefs}>
                              {item.department ? (
                                <span
                                  className="badge rounded-pill text-bg-primary"
                                  style={{
                                    background: item.department?.color,
                                    borderColor: item.department?.color,
                                    color: getTextColor(item.department?.color),
                                  }}
                                  key={Math.random()}
                                >
                                  {item.department?.name}
                                </span>
                              ) : (
                                <span>No department</span>
                              )}
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>
                                {item.cpc && item.cpc.firstName && item.cpc.lastName
                                  ? item.cpc.firstName + " " + item.cpc.lastName
                                  : "-"}
                              </span>
                            </DataTableRow>
                            <DataTableRow>
                              <ul className="nk-tb-actions table-action">
                                <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"detail" + item.id}
                                    icon="eye-fill"
                                    direction="top"
                                    text="Detail"
                                  />
                                </li>
                                {!roleFunctions.includes("update") || (
                                  <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"edit" + item.id}
                                      icon="edit-alt-fill"
                                      direction="top"
                                      text="Edit"
                                    />
                                  </li>
                                )}
                                {!roleFunctions.includes("delete") || (
                                  <li className="nk-tb-action" onClick={() => onDeleteClick(item.id, item.name)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"delete" + item.id}
                                      icon="trash-fill"
                                      direction="top"
                                      text="Hapus"
                                    />
                                  </li>
                                )}
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.createdAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={modal.add}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ add: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Nomor Whatsapp</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nomor Whatsapp</label>
                      <div className="form-control-wrap">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">+62</span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            name="waNumber"
                            placeholder="Ketik Nomor Whatsapp"
                            maxLength="13"
                            minLength="9"
                            value={numberValue}
                            onChange={(e) => numberHandleChange(e)}
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                        </div>
                      </div>
                      {errors.waNumber && <span className="custom-invalid">{errors.waNumber.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="custom-invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Department</label>
                      <RSelect
                        name="department"
                        options={dataDepartment}
                        placeholder="Pilih Department"
                        styles={{
                          singleValue: (styles, { data }) => {
                            const color = data.color;
                            const textColor = getTextColor(data.color);
                            return {
                              ...styles,
                              background: color + " !important",
                              borderRadius: "1.5rem !important",
                              color: textColor + "!important",
                              paddingLeft: "10px !important",
                              paddingRight: "10px !important",
                            };
                          },
                        }}
                        onChange={(e) => departmentSelected(e)}
                      ></RSelect>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Profit Center Contact</label>
                      <RSelect
                        name="cpc"
                        options={selectedCpcOpt}
                        placeholder="Pilih User"
                        onChange={(e) => cpcSelected(e)}
                      ></RSelect>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Api Key</label>
                      <input
                        className="form-control"
                        type="text"
                        name="apiKey"
                        defaultValue={formData.apiKey}
                        placeholder="Enter Api Key"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.apiKey && <span className="custom-invalid">{errors.apiKey.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          color="primary"
                          outline
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modal.detail}
          toggle={() => setModal({ detail: false })}
          backdrop="static"
          keyboard={false}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Detail Nomor Whatsapp</h5>
              <Block className="d-flex justify-content-end">
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          {roleFunctions.includes("update") && (
                            <Button
                              color="primary"
                              className={editContent ? "btn d-none" : ""}
                              onClick={() => setEditContent(true)}
                            >
                              Ubah
                            </Button>
                          )}
                        </li>
                        <li className="nk-block-tools-opt">
                          {roleFunctions.includes("delete") && (
                            <Button
                              color="danger"
                              outline
                              className="btn"
                              onClick={() => onDeleteClick(formData.id, formData.name)}
                            >
                              Hapus
                            </Button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </Block>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nomor Whatsapp</label>
                      <div className="form-control-wrap">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">+62</span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            name="waNumber"
                            placeholder="Ketik Nomor Whatsapp"
                            maxLength="13"
                            minLength="9"
                            value={numberValue}
                            disabled={!editContent}
                            onChange={(e) => numberHandleChange(e)}
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                        </div>
                        {errors.waNumber && <span className="invalid">{errors.waNumber.message}</span>}
                        {formData.waNumber.length > 15 && (
                          <span className="custom-invalid">Max length of number is 13</span>
                        )}
                        {formData.waNumber.length !== 0 && formData.waNumber.length < 9 && (
                          <span className="custom-invalid"> Min length of number is 9</span>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        disabled={!editContent}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Department</label>
                      <RSelect
                        name="department"
                        options={dataDepartment}
                        defaultValue={formData.department}
                        styles={{
                          singleValue: (styles, { data }) => {
                            // console.log("data", data);
                            const color = data?.color;
                            if (!color) return { ...styles };
                            const textColor = getTextColor(color);
                            return {
                              ...styles,
                              background: color + " !important",
                              borderRadius: "1.5rem !important",
                              color: textColor + "!important",
                              paddingLeft: "10px !important",
                              paddingRight: "10px !important",
                            };
                          },
                        }}
                        placeholder="Pilih Department"
                        disabled={!editContent}
                        onChange={(e) => departmentSelected(e)}
                      ></RSelect>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Profit Center Contact</label>
                      <RSelect
                        name="callCenterProfit"
                        options={selectedCpcOpt}
                        defaultValue={formData.callCenterProfit}
                        placeholder="Pilih User"
                        disabled={!editContent}
                        onChange={(e) => cpcSelected(e)}
                      ></RSelect>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Api Key</label>
                      <input
                        className="form-control"
                        type="text"
                        name="apiKey"
                        defaultValue={formData.apiKey}
                        disabled={!editContent}
                        placeholder="Enter Api Key"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.apiKey && <span className="custom-invalid">{errors.apiKey.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12" className={editContent ? "" : "d-none"}>
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          color="primary"
                          outline
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                  <Col size="12">
                    <p className="text-soft">
                      Created at {moment(formData.createdAt).format("MMM DD, YYYY, h:mm")} by {formData.createdBy}
                    </p>
                    <p className="text-soft">
                      Modified at {moment(formData.updatedAt).format("MMM DD, YYYY, h:mm")}{" "}
                      {formData.updatedBy !== "-" ? "by" + formData.updatedBy : ""}
                    </p>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Channels;
