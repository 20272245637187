import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from "reactstrap";
import { Icon, UserAvatar } from "components/Component";
import { findUpper } from "utils/Utils";
import { KanbanTaskForm, KanbanBoardForm } from "./KanbanForms";

export const KanbanCard = ({ data, setData, card, index, column, kanbanFilter }) => {
  const [open, setOpen] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const toggleTaskModal = () => {
    setTaskModal(!taskModal);
  };

  const deleteTask = (id) => {
    let defaultData = data;
    delete defaultData.task[id];

    defaultData.columns[column.id].tasks = defaultData.columns[column.id].tasks.filter((item) => item !== id);

    setData({ ...defaultData });
  };
  const { id, title, desc, meta } = card;

  return (
    <React.Fragment>
      <div className="mt-2">
        <div className="kanban-item">
          <div className="kanban-item-title mb-1">
            <div className="d-flex align-items-center">
              <Icon name="user-round" className="mr-1 kanban-icon yellow-color" />{" "}
              <h6 className="title">{title}</h6>
            </div>
          </div>
          {kanbanFilter.Status && (
            <ul className="kanban-item-tags mb-1">
              {meta?.tags.map((tag, index) => (
                <li key={index}>
                  <Badge color={tag.theme}>{tag.value}</Badge>
                </li>
              ))}
            </ul>
          )}
          {kanbanFilter.Address && (
            <div className="kanban-item-text mb-1">
              <p>{meta?.address}</p>
            </div>
          )}
          {kanbanFilter.City && (
            <div className="kanban-item-text mb-1" style={{ fontWeight: 600 }}>
              <p>{meta?.city}</p>
            </div>
          )}
          <div className="kanban-item-text mb-1">
            <p>Start Chat : {meta?.startDate}</p>
          </div>
          <div className="kanban-item-text mb-1">
            <p>Last Chat : {meta?.endDate}</p>
          </div>
          {kanbanFilter.Sales && (
            <div className="kanban-item-text mb-1 d-flex" style={{ fontWeight: 600, gap: "10px" }}>
              <div
                style={{
                  borderRadius: "100%",
                  border: "1px solid #526484",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {meta?.sales?.slice(0, 1)}
              </div>
              <p>{meta?.sales}</p>
            </div>
          )}
          {kanbanFilter.Email && (
            <div className="kanban-item-text mb-1 d-flex align-items-center">
              <Icon name="emails-fill" className="mr-1" style={{ fontSize: 16, color: "#F73164" }}></Icon>
              <p>{meta?.email}</p>
            </div>
          )}
          {kanbanFilter.Phone && (
            <div className="kanban-item-text mb-1 d-flex align-items-center">
              <Icon name="call-fill" className="mr-1" style={{ fontSize: 16, color: "#F73164" }}></Icon>
              <p>{meta?.phone}</p>
            </div>
          )}
          {open && kanbanFilter.Product && meta?.product?.length > 0 && meta?.product[0] !== "" && (
            <div style={{ backgroundColor: "#F5F6FB", padding: "8px 11px" }}>
              <p>{meta?.product}</p>
            </div>
          )}
          <div className="kanban-item-text mb-1">
            <p>{desc}</p>
          </div>
          {kanbanFilter.Media && (
            <div className="kanban-item-text">
              <Badge color="white">{meta?.platform}</Badge>
            </div>
          )}
          <div style={{ cursor: 'pointer' }} onClick={toggleOpen}>
            Show {open ? 'less' : 'more'}
          </div>
        </div>
      </div>
      <Modal size="lg" isOpen={taskModal} toggle={toggleTaskModal}>
        <KanbanTaskForm toggle={toggleTaskModal} data={data} setData={setData} editTask={card} taskToBoard={column} />
      </Modal>
    </React.Fragment>
  );
};

export const KanbanCardList = ({ data, setData, tasks, column, kanbanFilter }) => {
  return tasks?.length > 0 ? (
    tasks.map((task, index) => {
      const card = data.task[task];
      
      return (
        <KanbanCard
          kanbanFilter={kanbanFilter}
          card={card}
          data={data}
          setData={setData}
          key={card.id}
          index={index}
          column={column}
        />
      );
    })
  ) : (
    <div className="kanban-drag"></div>
  );
};

export const KanbanColumn = ({ data, setData, column, index, kanbanFilter }) => {
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  //console.log('kanban column', column);

  const toggleModal = () => {
    setOpen(!open);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const deleteBoard = (item) => {
    let defaultData = data;
    delete defaultData.columns[item.id];
    defaultData.columnOrder = defaultData.columnOrder.filter((el) => el !== item.id);

    setData({ ...defaultData });
  };

  const emptyList = (item) => {
    let defaultData = data;
    defaultData.columns[item.id].tasks = [];

    setData({ ...defaultData });
  };

  return (
    <React.Fragment>
      <div className={`kanban-board kanban-${column.theme}`}>
        <div className="kanban-title-board">
          <div className="kanban-title-content mb-1 mt-1">
            <h6 className="title">{column.text}</h6>
            <span className="badge badge-pill badge-outline-light text-dark">{column.tasks.length}</span>
          </div>
          <div className="kanban-title-content">
            {/* Opsional: Dropdown yang sebelumnya digunakan */}
          </div>
        </div>
        <div className="kanban-drag">
          <KanbanCardList
            kanbanFilter={kanbanFilter}
            data={data}
            setData={setData}
            tasks={column.tasks}
            column={column}
          />
          {/* Opsional: Tombol tambah tugas yang sebelumnya digunakan */}
        </div>
      </div>

      <Modal size="lg" isOpen={open} toggle={toggleModal}>
        <KanbanTaskForm toggle={toggleModal} data={data} setData={setData} taskToBoard={column} />
      </Modal>

      <Modal size="lg" isOpen={editModal} toggle={toggleEditModal}>
        <KanbanBoardForm toggle={toggleEditModal} data={data} setData={setData} editBoard={column} />
      </Modal>
    </React.Fragment>
  );
};
